import { axios } from '@/utils/request'

const api = {
    _service: "/PDM_HSM"
}

export default api


//-------------------分页获取导入套餐表列表-------------------
export function GetBoxItemListByPage(parameter) {
    return axios({
        url: api._service + "/GetBoxItemListByPage",
        method: 'post',
        data: parameter
    })
}
